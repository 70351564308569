/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */

import React, { Component } from 'react';
import NotificationSystem from 'rc-notification';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Detector } from 'react-detect-offline';

import { BasicNotification, FullWideNotification } from '../../../shared/components/Notification';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const mapStateToProps = state => ({ userInfo: state.userInfo, sidebar: state.sidebar });

let notification = null;


class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    changeToInvintus: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    userInfo: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    sidebar: PropTypes.object.isRequired,
  };


  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notification = n);
  }

  componentWillUnmount() {
    if (!notification === null) {
      notification.destroy();
    }
  }

  showNotification = (nTitle, nMessage) => {
    notification.notice({
      content: <BasicNotification
        color="primary"
        title={nTitle}
        message={nMessage}
      />,
      duration: 5,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
  };

  showOfflineNotice = (nTitle, nMessage) => {
    notification.notice({
      content: <FullWideNotification
        color="warning"
        title={nTitle}
        message={nMessage}
      />,
      // duration: 5,
      closable: false,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
  };


  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  launchChatWidget() {
    const { userInfo } = this.props;
    if (window.fcWidget) {
      const restoreId = null;
      window.fcWidget.init({
        token: '5f33c0ac-167d-44f9-9db0-89ce70b3424a',
        host: 'https://wchat.freshchat.com',
        externalId: `${userInfo.data.clientId}_${userInfo.data.userId}`,
        restoreId: restoreId || null,
        config: {
          // showFAQOnOpen: true,
          hideFAQ: false,
          cssNames: {
            widget: 'custom_fc_frame',
          },
          actions: {
            csat_yes: 'Yes, Resolved',
            csat_no: 'No, Resolved',
            push_notify_yes: 'Notify',
            push_notify_no: 'No Notify',
            tab_faq: 'Knowledge',
            tab_chat: 'Message',
            csat_submit: 'Submit Review Comments',
          },

          headerProperty: {
            direction: 'ltr',
            hideChatButton: true,
          },
        },
      });
      window.fcWidget.on('widget:loaded', () => {
        // console.log('widget loaded');

        window.fcWidget.on('unreadCount:notify', () => {
          // eslint-disable-next-line no-return-assign
          let alert = NotificationSystem.newInstance({}, n => alert = n);

          this.showNotification(alert, 'a new support chat message was received', 'open chat support to read');
          // console.log(resp);
          // TODO: use badge at support for new messages OR notification at top
        });
        window.fcWidget.on('widget:closed', () => {

        });
        window.fcWidget.on('widget:opened', () => {

        });
      });

      window.fcWidget.on('widget:closed', () => {
        // console.log('widget closed');
      });

      window.fcWidget.user.get((resp) => {
        const status = resp && resp.status;
        const data = resp && resp.data;
        if (status !== 200) {
          window.fcWidget.user.setProperties({
            firstName: userInfo.data.firstName, // user’s first name
            lastName: userInfo.data.lastName, // user’s last name
            email: userInfo.data.email, // user’s email address
            InvUserID: userInfo.data.userId,
            ClientID: userInfo.data.clientId,
          });
          window.fcWidget.on('user:created', () => {
            /* const status = resp && resp.status;
            const data = resp && resp.data; */
            if (status === 200) {
              if (data.restoreId) {
                // TODO: Update restoreId in cookie or local storage
              }
            }
          });
        }
      });
      window.fcWidget.open();
    }
  }

  launchSupportChat() {
    const { userInfo } = this.props;
    // Support/FAQ ticketing widget
    if (window.FreshworksWidget) {
      window.FreshworksWidget('boot');
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${userInfo.data.firstName} ${userInfo.data.lastName}`, // user’s first name
        email: userInfo.email,
      });
      window.FreshworksWidget('prefill', 'ticketForm', {
        custom_fields: {
          ClientID: userInfo.data.clientId,
          InvUserID: userInfo.data.userId,
          externalId: `${userInfo.data.clientId}_${userInfo.data.userId}`,
        },
      });

      window.FreshworksWidget('hide', 'ticketForm', ['email']);
      // window.FreshworksWidget('close');
      window.FreshworksWidget('open');
      // this.setState({SupportBotOpen:true})
    }
  }

  render() {
    const {
      changeToDark, changeToLight, changeToInvintus, sidebar,
    } = this.props;
    return (

      <div className="sidebar__content">
        <Detector
          onChange={(c) => {
            console.log('detector', c);
            // this.showOfflineNotice('OFFLINE', 'Your internet has gone away');

            /* if (!notification === null) {
            notification.destroy();
            notification = null;
          } */
          }}
          render={({ online }) => (
            <div>{online}
            </div>
          )}
        />
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard" icon="tachometer-alt" route="/" onClick={this.hideSidebar} />
          <SidebarLink title="Analytics" icon="chart-pie" route="/analytics" onClick={this.hideSidebar} />
          <SidebarLink title="Events List" icon="list-alt" route="/events" onClick={this.hideSidebar} />
          <SidebarLink title="Create Event" icon="calendar-plus" route="/events/create" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Media Settings" icon="photo-video" menuState={sidebar}>
            <SidebarLink title="Global Media Library" route="/mediaSettings/globalMediaLibrary" onClick={this.hideSidebar} />
            <SidebarLink title="Live Video Sources" route="/liveSources" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Delivery Settings" icon="arrow-alt-circle-right" menuState={sidebar}>
            <SidebarLink title="Player Settings" route="/mediaSettings/playerSettings" onClick={this.hideSidebar} />
            <SidebarLink title="Channel Settings" route="/mediaSettings/channelSettings" onClick={this.hideSidebar} />
            <SidebarLink title="OTT Settings" route="/mediaSettings/ottSettings" onClick={this.hideSidebar} />
            <SidebarLink title="Ad Campaigns" route="/mediaSettings/advertising" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Metadata Settings" icon="toolbox" menuState={sidebar}>
            <SidebarLink title="Bulk Event Import" route="/configure/eventsImport" onClick={this.hideSidebar} />
            <SidebarLink title="Categories" route="/configure/categories" onClick={this.hideSidebar} />
            <SidebarLink title="Keywords" route="/configure/keywords" onClick={this.hideSidebar} />
            <SidebarLink soonLink title="Transcript Dictionary" route="#" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Users/Account Settings" icon="user-cog">
            {/* only Authorized users */}
            <SidebarLink title="Account" route="/account/settings" onClick={this.hideSidebar} />
            <SidebarLink title="Technicians" route="/configure/technicians" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Help" icon="question-circle" menuState={sidebar}>
            <button type="button" className="sidebar__link" onClick={() => { this.launchChatWidget(); }}>
              <p className="sidebar__link-title">Live Chat Support</p>
            </button>
            <button type="button" className="sidebar__link" onClick={() => { this.launchSupportChat(); }}>
              <p className="sidebar__link-title">Support Ticket</p>
            </button>
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="UI themes" icon="layer-group" menuState={sidebar}>
            <button type="button" className="sidebar__link" onClick={changeToInvintus}>
              <p className="sidebar__link-title">Invintus Theme</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory>

        </ul>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SidebarContent);
