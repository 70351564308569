/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
// import Breadcrumbs from '../../shared/components/Breadcrumbs';

import EventList from './views/List';
import EventCalendar from './views/Calendar';
import EventTimeline from './views/Timeline';
// import { NavLink } from 'react-router-dom';

class Events extends PureComponent {
  constructor() {
    super();
    this.state = {
      activeTab: '1',
    };
  }

  toggleTabView = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  filter = (payload) => {
    // eslint-disable-next-line no-console
    console.log('i did foo', payload);
  }

  render() {
    const { activeTab } = this.state;

    return (
      <Col md={12} lg={12} xl={12}>
        {/* <Breadcrumbs /> */}

        <div className="text-center">
          <h3 className="bold-text">Events Manager</h3>
          {/*  <h5 className="subhead">add filters here</h5> */}

        </div>
        <div className="tabs tabs--justify tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggleTabView('1');
                  }}
                >
                      List
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggleTabView('2');
                  }}
                >
                      Calendar
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    this.toggleTabView('3');
                  }}
                >
                      Timeline
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Card>
                  <CardBody>
                    <EventList />
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <p>Calendar view here
                </p>
              </TabPane>
              <TabPane tabId="3">
                <p>Timline view here
                </p>
              </TabPane>
            </TabContent>
          </div>
        </div>

      </Col>

    );
  }
}

const mapStateToProps = state => ({
  events: state.events,
  clientInfo: state.clientInfo,
});

Event.propTypes = {
  events: PropTypes.object,
  clientInfo: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object,
};

export default connect(mapStateToProps)(Events);
