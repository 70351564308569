import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

const EventControls = () => (
  <Col md={12} lg={4} xl={4}>
    <Card className="card--not-full-height">
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">controls</h5>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default EventControls;
