/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react';

class Create extends PureComponent {
  render() {
    return (
      <div>
        <h6>create event placeholder</h6>
        <span>place holder</span>
      </div>
    );
  }
}

export default Create;
