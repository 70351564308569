/* eslint-disable no-console */

import React from 'react';
import { render } from 'react-dom';
import { detect } from 'detect-browser';
import App from './containers/App/App';
import { Auth0Provider } from './Auth/react-auth0-wrapper';
import config from './Auth/auth_config.json';
import DependencyInjector from './util/dependency-injector';
import FailedLoad from './containers/DefaultPages/FailedLoad';

const browser = detect();
let browserIsSupported = false;

switch (browser && browser.name) {
  case 'chrome':
  case 'firefox':
  case 'edge':
  case 'safari':
    browserIsSupported = true;
    break;
  default:
    console.log('not supported', browser);
}


const dependencies = [
  {
    src: '//cdn.scaledrone.com/scaledrone.min.js',
    required: true,
    type: 'js',
    name: 'ScaleDrone',
    alreadyLoaded: () => !!Object.prototype.hasOwnProperty.call(window, 'ScaleDrone'),
  },
  {
    src: 'https://wchat.freshchat.com/js/widget.js',
    required: true,
    type: 'js',
    name: 'fcWidget',
    alreadyLoaded: () => !!Object.prototype.hasOwnProperty.call(window, 'fcWidget'),
  },

];


const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

// wrap init render logic in di for important injections
DependencyInjector.inject(dependencies, (failedDep) => {
  if (failedDep) {
    render(
      <FailedLoad
        source={failedDep.src}
        name={failedDep.name}
      />,
      document.getElementById('root'),
    );
  } else if (!browserIsSupported) {
    render(
      <FailedLoad
      // CHANGE ME:  this to browser not supported component
        source="NA"
        name={browser.name}
      />,
      document.getElementById('root'),
    );
  } else {
    render(
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>,
      document.getElementById('root'),
    );
  }
});
