/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import MainWrapper from '../MainWrapper';
import WrappedRoutes from './WrappedRoutes';
import PrivateRoute from './PrivateRoute';

const Router = () => (
  <MainWrapper>
    <main>
      <PrivateRoute exact component={WrappedRoutes} />
    </main>
  </MainWrapper>
);

export default Router;
