/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withTranslation } from 'react-i18next';
// import TargetIcon from 'mdi-react/TargetIcon';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'reactstrap';

const Launcher = ({
  title, icon, text, route, onClick, bgColor,
}) => (
  <Col md={12} xl={3} lg={6} xs={12}>
    <NavLink
      to={route}
      onClick={onClick}
    >
      <div className="dashboard__launchers" style={{ backgroundColor: bgColor }}>

        <p className="dashboard__launchers-title">
          { title }
        </p>
        <p>{ text }</p>
        <FontAwesomeIcon icon={icon} className="dashboard__launchers svg" />
      </div>
    </NavLink>
  </Col>
);

Launcher.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  route: PropTypes.string,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
};

Launcher.defaultProps = {
  icon: '',
  route: '/',
  bgColor: '',
  onClick: () => {},
};

export default withTranslation('common')(Launcher);
