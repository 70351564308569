/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
import React from 'react';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Breadcrumbs = ({ breadcrumbs }) => (
  <React.Fragment>
    <ul className="breadcrumb">
      {
      (breadcrumbs.length > 1) ? (
        breadcrumbs.map(({ match, breadcrumb }) => {
          if (breadcrumb.props.children === 'Home') {
            return (
              <li key={match.url}>
                <NavLink
                  to={match.url}
                ><FontAwesomeIcon icon="home" />
                </NavLink>
              </li>
            );
          }
          return (
            <li key={match.url}>
              <NavLink
                to={match.url}
              >
                {breadcrumb} <FontAwesomeIcon icon="angle-double-right" />
              </NavLink>
            </li>
          );
        })
      ) : ''

    }


    </ul>


  </React.Fragment>

);
export default withBreadcrumbs()(Breadcrumbs);
