/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Offline } from 'react-detect-offline';
import LoadingIcon from 'mdi-react/LoadingIcon';

// import Notification from 'rc-notification';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
// import { BasicNotification } from '../../shared/components/Notification';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { changeThemeToDark, changeThemeToLight, changeThemeToInvintus } from '../../redux/actions/themeActions';
import { SidebarProps } from '../../shared/prop-types/ReducerProps';

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentDidMount() {
    // notification = Notification.newInstance({ style: { top: 65 } });
    // NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    // setTimeout(() => showNotification(), 700);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUnmount() {
    // notification.destroy();
  }

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  changeToInvintus = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToInvintus());
  };

  render() {
    const { sidebar } = this.props;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
    });

    return (


      <div className={layoutClass}>
        <Offline>
          <div className="panel__offline">
            <div className="panel_offlineText">Network Connection Lost</div>
            <LoadingIcon />
          </div>
        </Offline>
        <Topbar
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
        />
        <Sidebar
          sidebar={sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeToInvintus={this.changeToInvintus}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />

      </div>


    );
  }
}

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
  theme: state.theme,
  userInfo: state.userInfo,
  clientInfo: state.clientInfo,
}))(Layout));
