/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { format, addDays } from 'date-fns';
import NumberFormat from 'react-number-format';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../../../shared/components/LandingPanel';

const style = {
  left: 0,
  width: 150,
  lineHeight: '10px',
  position: 'relative',
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
      ))
    }
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

const formattedData = (data) => {
    if (data) {
      return [
        { name: 'LIVE', value: data.live, fill: '#4ce1b6' },
        { name: 'VOD', value: data.vod, fill: '#70bbfd' },
        { name: '24/7', value: data.live247, fill: '#f6da6e' },
      ];
    }
    return [
      { name: 'LIVE', value: 'loading', fill: '#4ce1b6' },
      { name: 'VOD', value: 'loading', fill: '#70bbfd' },
      { name: '24/7', value: 'loading', fill: '#f6da6e' },
    ];
};

const VisitorsSessions = ({
  sessionStats,
}) => ((sessionStats) ? (
  <Panel
    lg={6}
    xl={3}
    md={12}
    title="Viewers"
    rSubhead="Past 30 Days"
  >
    <div className="dashboard__visitors-chart">
      <p className="dashboard__visitors-chart-title">Total viewers from
        <span> {format(addDays(new Date(), -30), 'MM-dd-yy')} to {format(new Date(), 'MM-dd-yy')}</span>
      </p>
      <p className="dashboard__visitors-chart-number">
        <NumberFormat value={sessionStats.verifiedPlayCount} displayType="text" thousandSeparator />
      </p>
      <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={220}>
        <PieChart width={90} height={90}>
          <Tooltip />
          <Pie data={formattedData(sessionStats)} dataKey="value" cx="50%" cy="70%" innerRadius={50} outerRadius={90} label />
          <Legend layout="horizontal" content={renderLegend} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </Panel>
) : (
  <Panel
    lg={6}
    xl={3}
    md={12}
    title="Viewer Sessions"
    subhead="Past 30 Days"
  ><div className="panel__refresh"><LoadingIcon /></div>
  </Panel>
));

VisitorsSessions.propTypes = {
  sessionStats: PropTypes.object,
};

export default (VisitorsSessions);
