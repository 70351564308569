/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const mapStateToProps = state => ({ clientInfo: state.clientInfo });

class TopbarClient extends PureComponent {
  render() {
    const { clientInfo } = this.props;
    return (
      (!clientInfo.pending)
        ? (
          <div>
            <div className="topbar__client-name">
              { clientInfo.data.name }
            </div>
            <div className="topbar__client-sm-title">Customer ID: { clientInfo.data.clientID }</div>
          </div>
        )
        : (
          <div className="topbar__search">
            <FontAwesomeIcon icon="spinner" spin />
          </div>
        )
    );
  }
}
export default connect(mapStateToProps)(TopbarClient);
