/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  format, addDays, startOfMonth, endOfMonth,
} from 'date-fns';
import _ from 'underscore';
import { Table } from 'reactstrap';
import { Pie, defaults } from 'react-chartjs-2';
import {
  ResponsiveContainer,
} from 'recharts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { fetchMostWatched } from '../../../redux/actions/landingStatsActions';

import Panel from '../../../shared/components/LandingPanel';

const colors = {
  blue: '#36a2ec',
  red: '#fa6283',
  yellow: '#fdcd56',
  green: '#56fd72',
  lavender: '#f9a4ff',
};

function PieChart(data) {
  const evs = _(data.events).sortBy('plays').reverse().slice(0, 5);

  const chartData = {
    labels: evs.map(ev => ev.title),
    datasets: [{
      data: _(evs).map('plays'),
      backgroundColor: Object.keys(colors).map((k, i) => colors[Object.keys(colors)[i]]),
      hoverBackgroundColor: Object.keys(colors).map((k, i) => colors[Object.keys(colors)[i]]),
    }],
  };

  return (
    <div className="dashboard__stat-most-watched" style={{ height: '200px', marginBottom: '2em' }}>
      <Pie
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          legend: {
            display: true,
            position: 'left',
            fullWidth: false,
          },
          plugins: {
            datalabels: {
              display: true,
              borderColor: 'gray',
              backgroundColor: 'rgba(236, 236, 236, 0.5)',
              borderRadius: 5,
              borderWidth: 1,
              color: 'black',
              font: {
                weight: 'bold',
              },
              formatter(value) {
                return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              },
              anchor: 'center',
              offset: 30,
            },
          },
        }}
      />
    </div>
  );
}


function List(data) {
  const evs = _(data.events).sortBy('plays').reverse().slice(0, 5);

  return (
    <div className="dashboard__stat-most-watched">
      <ul className="list-unstyled">
        {evs.map((ev, i) => [
          (<li key={i}>
            <Link
              to={`/event/${ev.eventID}`}
              style={{
                display: 'inline-block',
                width: '80%',
              }}
            >
              {ev.title}
            </Link>
            <span style={{
              display: 'inline-block',
              float: 'right',
            }}
            >
              {ev.plays}
            </span>
          </li>),
          (i < evs.length - 1 ? <hr key={`hr-${i}`} style={{ margin: '0.5em 0' }} /> : null),
        ])}
      </ul>
    </div>

  );
}

const MostWatched = ({
  mostWatched, clientInfo, dispatch,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);

  // clean up on unload, stops polling if set too.
  useEffect(() => () => {}, []);

  if (!clientInfo.pending) {
    if (firstLoad) {
      setFirstLoad(false);
      const payload = {
        clientID: clientInfo.data.clientID,
        startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        stopDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
      };
      dispatch(fetchMostWatched(payload));
      return (
        <Panel
          lg={6}
          xl={6}
          md={12}
          title="Most Watched"
          subhead="Most Watched events in billing period"
        ><div className="panel__refresh"><LoadingIcon /></div>
        </Panel>
      );
    }
  }
  // sort by # of plays and show only the top 5
  const evs = _(mostWatched.data).sortBy('plays').reverse().slice(0, 5);

  return (
    (!mostWatched.pending && !mostWatched.error)
      ? (
        <Panel
          lg={6}
          xl={5}
          md={12}
          title="MOST WATCHED"
          subhead="This Month"
    // subhead="How effective your business is in comparison with the previous month"
          panelClass="panel--narrow"
        >
          <ResponsiveContainer height={400} className="dashboard__active-users-chart">
            <div>
              <PieChart events={mostWatched.data} />
              <List events={mostWatched.data} />
            </div>
          </ResponsiveContainer>
        </Panel>
      )
      : (
        <Panel
          title="MOST WATCHED"
          subhead="This Month"
        >
          <div className="panel__refresh">Fetching Data<LoadingIcon /></div>
        </Panel>
      )
  );
};
const mapStateToProps = state => ({
  mostWatched: state.mostWatched,
  clientInfo: state.clientInfo,
});

MostWatched.propTypes = {
  mostWatched: PropTypes.object,
  clientInfo: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};


MostWatched.PieChart = PieChart;
MostWatched.List = List;

export default connect(mapStateToProps)(MostWatched);
