/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  format, addDays,
} from 'date-fns';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { Badge, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import fetchEventList from '../../../redux/actions/eventListActions';

import Panel from '../../../shared/components/LandingPanel';


const UpcomingEvents = ({ eventList, clientInfo, dispatch }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  // clean up on unload, stops polling if set too.
  useEffect(() => () => {}, []);
  if (!clientInfo.pending) {
    if (firstLoad) {
      setFirstLoad(false);
      const payload = {
        clientID: clientInfo.data.clientID,
        startDateTime: format(new Date(), 'yyyy-MM-dd'),
        stopDateTime: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
      };
      dispatch(fetchEventList(payload));
      return (
        <Panel
          lg={6}
          xl={6}
          md={12}
          title="Upcoming Events"
          subhead="1 week period"
        ><div className="panel__refresh"><LoadingIcon /></div>
        </Panel>
      );
    }
  }

  const badgeStatus = (stat) => {
    switch (stat) {
      case 'live':
        return 'danger';
      case 'publishing':
      case 'published':
        return 'success';
      default:
        return 'info';
    }
  };

  function eventItem(Item) {
    return (
      <tr key={Item.eventID}>
        <td style={{ width: '80%' }}><Link to={`/events/${Item.eventID}`}>{Item.title}</Link></td>
        <td style={{ width: '20%' }}>{format(new Date(Item.startDateTime), 'MMM do HH:mm a')}</td>
        { <td><Badge color={badgeStatus(Item.eventStatus)}>{Item.eventStatus}</Badge></td> }
      </tr>
    );
  }


  return (
    (!eventList.pending && !eventList.error)
      ? (
        <Panel
          title="Upcoming Events"
          subhead="1 week period"
        >
          <Table responsive striped bordered className="table--bordered-scroll">
            <tbody>

              {
                (eventList.data.length > 0)
                  ? eventList.data.map(item => (
                    eventItem(item)
                  ))
                  : <tr><td>NO UPCOMING EVENT FOUND FOR THE WEEK</td></tr>


              }
            </tbody>
          </Table>
        </Panel>
      ) : (
        <Panel
          title="Upcoming Events"
          subhead="1 week period"
        >
          <div className="panel__refresh">Fetching Data<LoadingIcon /></div>
        </Panel>
      )
  );
};

const mapStateToProps = state => ({
  eventList: state.eventList,
  clientInfo: state.clientInfo,
});

UpcomingEvents.propTypes = {
  eventList: PropTypes.object,
  clientInfo: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(UpcomingEvents);
