/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PieChart, Pie } from 'recharts';
import Panel from '../../../shared/components/LandingPanel';


const okColor = '#4ce1b6';
const warnColor = '#ebcc34';
const overColor = '#ff4861';

const colorOps = (stat) => {
  if (stat >= 100) return overColor;
  if (stat >= 75) return warnColor;
  return okColor;
};

const thresholds = [
  { value: 60, fill: colorOps(60) },
  { value: 20, fill: colorOps(75) },
  { value: 20, fill: colorOps(100) },
];


const AccountOverview = ({
  accountStats, // dispatch,
}) => {
  const bandwidth = () => {
    if (accountStats.data) {
      const stat = accountStats.data['bandwidth % of plan'];
      const v = (stat >= 100) ? 100 : stat;
      return [
        { value: v, fill: colorOps(stat) },
        { value: 100 - v, fill: '#eeeeee' },
      ];
    }
    return [
      { value: 0, fill: okColor },
      { value: 0, fill: '#eeeeee' },
    ];
  };

  const storage = () => {
    if (accountStats.data) {
      const stat = accountStats.data['storage % of plan'];
      const v = (stat >= 100) ? 100 : stat;
      return [
        { value: v, fill: colorOps(stat) },
        { value: 100 - v, fill: '#eeeeee' },
      ];
    }
    return [
      { value: 0, fill: okColor },
      { value: 0, fill: '#eeeeee' },
    ];
  };

  return (

    <Panel
      md={12}
      lg={6}
      xl={3}
      xs={12}
      title="Account Usage"
      subhead="Account Usage month-to-date"
    >
      <div className="dashboard__stat">
        <div className="dashboard__stat-chart">

          <PieChart height={150} width={150}>
            <Pie
              startAngle={200}
              endAngle={-20}
              data={thresholds}
              dataKey="value"
              innerRadius="90%"
              outerRadius="100%"
            />
            <Pie
              startAngle={200}
              endAngle={-20}
              data={bandwidth()}
              dataKey="value"
              innerRadius="65%"
              outerRadius="89%"
            />
          </PieChart>


          <p className="dashboard__stat-label" style={{ color: colorOps(accountStats.data['bandwidth % of plan']) }}>
            {accountStats.data['bandwidth % of plan']}%
          </p>
        </div>
        <div className="dashboard__stat-info">
          <p>Bandwidth Usage of Plan</p>
          <h4 className="dashboard__-stat-number">
            {accountStats.data['bandwidth used']}
          </h4>
        </div>
      </div>
      <div className="dashboard__stat">
        <div className="dashboard__stat-chart">
          <PieChart height={150} width={150}>
            <Pie
              startAngle={200}
              endAngle={-20}
              data={thresholds}
              dataKey="value"
              innerRadius="90%"
              outerRadius="100%"
            />
            <Pie
              startAngle={200}
              endAngle={-20}
              data={storage()}
              dataKey="value"
              innerRadius="65%"
              outerRadius="89%"
            />
          </PieChart>
          <p className="dashboard__stat-label" style={{ color: colorOps(accountStats.data['storage % of plan']) }}>
            {accountStats.data['storage % of plan']}%
          </p>
        </div>
        <div className="dashboard__stat-info">
          <p>Storage Usage of Plan</p>
          <h4 className="dashboard__stat-number">
            {accountStats.data['storage used']}
          </h4>
        </div>
      </div>
    </Panel>
  );
};

AccountOverview.propTypes = {
  accountStats: PropTypes.object,
  // clientInfo: PropTypes.object,
  // dispatch: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  accountStats: state.accountStats,
  clientInfo: state.clientInfo,
});

export default connect(mapStateToProps)(AccountOverview);
