/* eslint-disable max-len */
/* eslint-disable no-var */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { format, addDays } from 'date-fns';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Col, Container, Row, Button,
} from 'reactstrap';
import { fetchLandingStats, fetchLandingStatsPoll, pollStop } from '../../redux/actions/landingStatsActions';
import fetchAccountStats from '../../redux/actions/accountStatsActions';
import Visits from './components/Visits';
import TotalPageViews from './components/TotalPageViews';
import NewUsers from './components/NewUsers';
import BounceRate from './components/BounceRate';
import AccountOverview from './components/AccountOverview';
import VisitorsSessions from './components/VisitorsSessions';
import BounceRateArea from './components/BounceRateArea';
import AudienceByCountry from './components/AudienceByCountry';
import BudgetStatistic from './components/BudgetStatistic';
import CurrentUsers from './components/template/CurrentUsers';
import MostWatched from './components/MostWatched';
import RealtimeViewerChart from './components/RealtimeViewerChart';
import NewsHero from './components/NewsHero';
/* import SessionShort from './components/SessionShort';
import ActiveUsersShort from './components/ActiveUsersShort';
import NewUsersShort from './components/NewUsersShort';
import PageViewsShort from './components/PageViewsShort';
import AppTileClicks from './components/AppTileClicks'; */
import UpcomingEvents from './components/UpcomingEvents';
import SocialMarketing from './components/SocialMarketing';
import Launcher from './components/Launchers';

const mapStateToProps = state => ({
  landingStats: state.landingStats,
  clientInfo: state.clientInfo,
});

const DefaultDashboard = ({
  t, landingStats, clientInfo, dispatch,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);


  // eslint-disable-next-line consistent-return
  const runFetch = () => {
    const payload = {
      showCurrent: true,
      clientID: clientInfo.data.clientID,
      startDate: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
      stopDate: format(new Date(), 'yyyy-MM-dd'),
    };
      // dispatch stats in polling mode
    dispatch(fetchLandingStatsPoll(payload, 5000));
    dispatch(fetchAccountStats({ clientID: clientInfo.data.clientID }));
  };


  const handleFirstLoad = (fl) => {
    setFirstLoad(fl);
  };

  const handleCleanup = () => {
    // console.log('on kill', landingStats);
    dispatch(pollStop());
  };

  // clean up on unload, stops polling if set too.
  useEffect(() => () => {
    handleCleanup();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  if (!clientInfo.pending) {
    if (firstLoad) {
      handleFirstLoad(false);
      runFetch();
    }
  }


  return (
    <Container className="dashboard">
      <Row>
        <Launcher
          title="Go Live"
          icon="broadcast-tower"
          text="Setup an event to quickly go live"
          route="/events/create"
        />
        <Launcher
          title="Upload a video"
          icon="file-video"
          text="Quickly upload a video and create an event you intend to publish"
          route="/mediaSettings/globalMediaLibrary"
        />
        <Launcher
          title="Manage Events"
          icon="list-alt"
          text="Manage, Create, Update Live and VOD events"
          route="/events"
        />
        <Launcher
          title="Documentation"
          icon="info-circle"
          text="Get help and learn how to get the best out of Invintus"
          route="/documents"
        />
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          <h4 className="page-title">Dashboard</h4>
        </Col>
      </Row>
      <Row>
        <VisitorsSessions
          sessionStats={landingStats.data}
        />
        <RealtimeViewerChart
          sessionStats={landingStats.data}
        />
      </Row>
      <Row>
        <MostWatched />
        <AccountOverview />
        <Col md={12} xl={4} lg={6} xs={12}>
          <Row>
            <UpcomingEvents />
            <NewsHero />
          </Row>

        </Col>


      </Row>
      {/* <Row>
        <Visits />
        <TotalPageViews />
        <NewUsers />
        <BounceRate />
      </Row>
      <Row>
        <BounceRateArea />
        <CurrentUsers />
        <SocialMarketing />

        <BudgetStatistic />
        <AudienceByCountry />

      </Row> */}
    </Container>
  );
};
// const statsTimer = setInterval(DefaultDashboard.timer, 5000);
DefaultDashboard.propTypes = {
  t: PropTypes.func.isRequired,
  landingStats: PropTypes.object,
  clientInfo: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default compose(withTranslation('common'), connect(mapStateToProps))(DefaultDashboard);
