/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import fetchEvent from '../../../redux/actions/eventActions';


import EventCard from './components/EventCard';
import EventControls from './components/EventControls';
import EventAssets from './components/EventAssets';

const Event = (
  {
    match, event, dispatch, clientInfo,
  },
) => {
  const eventID = match.params.id;

  const [firstLoad, setFirstLoad] = useState(true);

  // clean up on unload, stops polling if set too.
  useEffect(() => () => {

  }, []);

  if (!clientInfo.pending) {
    if (firstLoad) {
      setFirstLoad(false);
      const payload = {
        clientID: clientInfo.data.clientID,
        eventID,
        includePrivate: true,
        showEncoder: true,
        showMediaAssets: true,
        showMediaDetails: true,
        showStreams: true,
        // add other ops
      };
      dispatch(fetchEvent(payload));
    }
  }
  return (
    (!event.pending && !event.error)
      ? (
        <Container>
          <Row>
            <EventCard
              event={event.data}
            />
            <EventControls />
          </Row>
          <Row>
            <Col md={12}>
              <h3 className="page-title page-title--not-last">EVENTS ASSETS</h3>
            </Col>
          </Row>
          <Row>
            <EventAssets />
          </Row>
        </Container>
      )
      : (
        (event.error)
          ? (
            <div className="full-page-notice">
              <div className="full-page-notice__icon-wrap">

                <h2>This event does not exists or has been removed</h2>
                <h4>{event.errorMsg}</h4>
                <hr />
                <p>Check the referring link or if you believe this is an error,
                please contact support from the support menu <FontAwesomeIcon className="sidebar__link-icon" icon="question-circle" />
                </p>

              </div>
            </div>
          )
          : (
            <div className="load">
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          )
      )
  );
};

const mapStateToProps = state => ({
  event: state.event,
  clientInfo: state.clientInfo,
});

Event.propTypes = {
  event: PropTypes.object,
  clientInfo: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object,
};

export default connect(mapStateToProps)(Event);
