/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import Panel from '../../../shared/components/Panel';

import Image404 from '../../../shared/img/logo/applogo-wtext.png';

class FailedLoad extends React.PureComponent {
  render() {
    const { source, name } = this.props;

    return (
      <div className="not-found">
        <div className="not-found__content">
          <img className="not-found-error__image" src={Image404} alt="404" />
          <h3 className="not-found__info">
          This application failed to load because it could not load the necessary requirements :(
          </h3>
          <div className="not-found__info">
            <Panel
            // eslint-disable-next-line react/jsx-boolean-value
              divider={true}
              title="Details of Issues Found Loading Control Center"
              color="danger"
            >
              <p>issue javascript: <strong>{ name }</strong></p>
              <p>remote or local file: <strong>{ source }</strong></p>
              <br />
              <h3>Things to know</h3>
              <ol>
                <li>
                  <p>
                        The javascript component: {name}, may be blocked by your corporate or local firewall,
                        contact your system administrator to un-block this domain or direct script path.
                  </p>
                </li>
                <li>
                  <p>
                        Your internet connection may have been interrupted. try the reload button below when your internet
                        connection is restored
                  </p>
                </li>
              </ol>


            </Panel>
            <a className="btn btn-primary" href="/">reload</a>
          </div>
        </div>

      </div>
    );
  }
}

export default FailedLoad;
