import React from 'react';
import { Col } from 'reactstrap';
import Assets from './Assets';

import catalogList from './test_list';

const EventAssets = () => (
  <Col md={12} lg={12}>
    <Assets items={catalogList.slice(0, 4)} />
  </Col>
);

export default EventAssets;
