/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-console */
/* eslint-disable no-useless-constructor */
import React, { Fragment } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Router from './Router/index';
import ScrollToTop from './ScrollToTop';
import changeUserData from '../../redux/actions/userActions';
import fetchClient from '../../redux/actions/clientActions';
import { WebsocketAdapter } from '../../redux/actions/connectedActions';

import store from '../../redux/store';
// import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import { useAuth0 } from '../../Auth/react-auth0-wrapper';

// add font library
library.add(fab, far, fas);

i18next.init(i18nextConfig);
let clientLoaded = false;
let init = true;

async function connectSocket(clientID, dispatch) {
  await WebsocketAdapter(clientID, dispatch)
  // eslint-disable-next-line no-unused-vars
    .then(({ wssreceive, wssconnection }) => {
      // wssreceive(store.dispatch);
      wssconnection(store.dispatch);
    }).catch((err) => {
      console.warn('WEBSOCKET DID NOT CONNECT', err);
    });
}

const App = () => {
  const initListener = () => {
    const storeState = store.getState();
    // look for a clientID from the fetchClient complete
    if (storeState.clientInfo.data.clientID) {
      clientLoaded = true;
    }
    if (clientLoaded && init) {
      init = false;
      // init the socket!! (release the hounds) waits for it.
      const { clientID } = storeState.clientInfo.data;
      connectSocket(clientID, store.dispatch);
    }
  };

  store.subscribe(initListener);

  const {
    loading, user,
    isAuthenticated, loginWithRedirect,
  } = useAuth0();
  const fn = async () => {
    if (!loading) {
      if (!isAuthenticated) {
        await loginWithRedirect({});
      } else {
        await store.dispatch(changeUserData({
          user,
        }));
        // eslint-disable-next-line prefer-destructuring
        const clientID = user['http://invintus_user_metadata'].clientID;
        const payload = {
          clientID,
          userEmail: user.email,
        };
        store.dispatch(fetchClient(payload));
      }
    }
  };
  fn();


  return (

    (!loading && isAuthenticated && user)
      ? (
        <Provider store={store}>
          <BrowserRouter>
            <I18nextProvider i18n={i18next}>
              <ScrollToTop>
                <Fragment>
                  <div>
                    <Router />
                  </div>
                </Fragment>
              </ScrollToTop>
            </I18nextProvider>
          </BrowserRouter>
        </Provider>
      )

      : (
        <div className={`load${loading ? '' : ' loaded'}`}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
      )

  );
};
App.propTypes = {
  // changeUserLoggedIn: PropTypes.func.isRequired,
  // changeUserData: PropTypes.func.isRequired,
};
export default hot(module)(App);
