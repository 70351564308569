/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import EdiText from 'react-editext';
/* import StarIcon from 'mdi-react/StarIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon'; */
// import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import ProductGallery from './ProductGallery';
import images from './imgs';
import EventTabs from './EventTabs';
// import ColorSelect from './ColorSelect';

const onSave = (saveData) => {
  console.log('from inline edit', saveData);
};

const EventCard = ({
  event,
}) => {
  // do something  here
  // eslint-disable-next-line no-console
  console.log('id as event card', event);
  return (
    <Col md={12} lg={8} xl={8}>
      <Card>
        <CardBody>
          <div className="event-card">
            <ProductGallery images={images} />
            <div className="event-card__info">
              <span className="event-typography-message">eventID : { event.eventID } </span>
              <h3 className="event-card__title">
                <EdiText
                  type="text"
                  value={event.title}
                  onSave={onSave}
                  editButtonClassName="btn btn-light"
                />

              </h3>
              {/* <h1 className="event-card__price">$17.19 <span className="event-card__old-price">$23</span></h1> */}
              <span className="typography-message">
                {ReactHtmlParser(event.description)}
              </span>
              <hr />
              <table className="project-summary__info">
                <tbody>
                  <tr>
                    <th>Event Date/Time:</th>
                    <td>
                      <EdiText
                        type="datetime"
                        value={event.startDateTime}
                        onSave={onSave}
                        // editButtonClassName="btn btn-light"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Event ID:</th>
                    <td>{ event.eventID }</td>
                  </tr>
                  <tr>
                    <th>Custom ID:</th>
                    <td>{ event.customID }</td>
                  </tr>
                  <tr>
                    <th>Client Brief:</th>
                    <td><a href="/">Design Brief_Global Co.txt</a></td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <EventTabs />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

EventCard.propTypes = {
  event: PropTypes.object,
  // clientInfo: PropTypes.object,
  // dispatch: PropTypes.func.isRequired,
};

export default EventCard;
