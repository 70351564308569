/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';
import xtend from 'xtend';
import { Line, defaults } from 'react-chartjs-2';
import {
  ResponsiveContainer,
} from 'recharts';
import { format } from 'date-fns';
import Panel from '../../../shared/components/LandingPanel';

defaults.global.responsive = true;
defaults.global.maintainAspectRatio = false;
defaults.global.animation = false;

const chartOptions = {
  fill: false,
  lineTension: 0.4,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 10,
};

const viewerHistory = [];

function createDataset(data) {
  // eslint-disable-next-line no-param-reassign
  data.time = format(new Date(), 'HH:mm:ss');
  viewerHistory.push(data);
  if (viewerHistory.length > 10) {
    // pop one off of the end
    viewerHistory.shift();
  }

  return viewerHistory.map(stat => ({
    archiveStreams: stat.activeVod,
    liveStreams: stat.activeLive,
    live247Streams: stat.active247,
    time: stat.time,
  }));
}

function _getTotal(data, key) {
  try {
    return data[key];
  } catch (err) {
    return 0;
  }
}

function getLiveTotal(data) {
  const tot = _getTotal(data, 'activeLive') + _getTotal(data, 'activeVod')
        + _getTotal(data, 'active247');
  return (tot < 1) ? tot : String(tot).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
function getLiveMaxInt(data) {
  const totAr = [_getTotal(data, 'activeLive'), _getTotal(data, 'activeVod'),
    _getTotal(data, 'active247')];
  const maxVal = Math.max(...totAr);
  return maxVal;
}
function getLiveMinInt(data) {
  const totAr = [_getTotal(data, 'activeLive'), _getTotal(data, 'activeVod'),
    _getTotal(data, 'active247')];
  const minVal = Math.min(...totAr);
  return minVal;
}
function createLineChartOpts(increment, max, min) {
  return {
    plugins: {
      datalabels: {
        display: true,
        borderColor(context) {
          return context.dataset.backgroundColor;
        },
        borderWidth: 1,
        backgroundColor(context) {
          const parts = context.dataset.backgroundColor.match(/[\d.]+/g);
          if (parts.length === 3) { parts.push(1); }
          parts[3] = 0.2;
          return `rgba(${parts.join(',')})`;
        },
        borderRadius: 5,
        color: 'black',
        font: {
          weight: 'normal',
        },
        formatter(value) {
          return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        align: 'top',
        anchor: 'end',
      },
    },
    maintainAspectRatio: false,
    animation: false,
    responsive: true,
    tooltips: {
      mode: 'label',
      /*  callbacks: {
                  label: function(tooltipItem, data) {
                      return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }, }, */
    },

    elements: {},
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: increment,
            suggestedMin: min.totPointMin,
            suggestedMax: max.totPointMax,
            callback(value) {
              return String(value)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
          showLabelBackdrop: true,
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          labels: {
            show: true,
          },
        },
      ],
    },

  };
}

class RealtimeViewerChart extends React.PureComponent {
    static propTypes = {
      sessionStats: PropTypes.object,
    };

    render() {
      const { sessionStats } = this.props;

      const liveData = createDataset(sessionStats).reverse();

      const liveTotal = getLiveTotal(sessionStats);
      // adds 20% to total to increase line chart est. max height
      const topActiveViewersInt = getLiveMaxInt(sessionStats);
      const tmpMax = Math.round(topActiveViewersInt + ((topActiveViewersInt / 100) * 20));
      // if the max is <=1 then add 1 more points to the graph so the percent is not lost
      const totPointMax = (tmpMax <= 1) ? (tmpMax + 1) : tmpMax;
      // subtracts 20% to total to set line chart est. min points height unless <=0
      const minActiveViewersInt = getLiveMinInt(sessionStats);
      const tmpMin = Math.round(minActiveViewersInt - ((topActiveViewersInt / 100) * 20));
      const totPointMin = (tmpMin <= 0) ? 0 : tmpMin;

      const historicalTotal = (_getTotal(sessionStats, 'vod')
            + _getTotal(sessionStats, 'live')
            + _getTotal(sessionStats, 'live247'));

      const historicalTotalFormatted = (`${_getTotal(sessionStats, 'vod')
            + _getTotal(sessionStats, 'live')
            + _getTotal(sessionStats, 'live247')}`)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      const colorRed = 'rgba(242, 81, 81, 0.7)';
      const colorBlue = 'rgba(0, 136, 238, 0.7)';
      const colorOrange = 'rgba(245, 171, 53, 0.7)';

      const liveChartData = {
        labels: liveData.map(datum => datum.time),
        datasets: [
          xtend(chartOptions, {
            label: '24/7 Streams',
            backgroundColor: 'rgba(245, 171, 53, 0.3)',
            borderColor: colorOrange,
            pointBorderColor: colorOrange,
            pointBackgroundColor: '#fff',
            pointHoverBackgroundColor: colorOrange,
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            data: liveData.map(datum => datum.live247Streams),
          }),

          xtend(chartOptions, {
            label: 'Live Streams',
            backgroundColor: 'rgba(242, 81, 81, 0.3)',
            borderColor: colorRed,
            pointBorderColor: colorRed,
            pointBackgroundColor: '#fff',
            pointHoverBackgroundColor: colorRed,
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            data: liveData.map(datum => datum.liveStreams),
          }),

          xtend(chartOptions, {
            label: 'Archive Streams',
            backgroundColor: 'rgba(0, 136, 238, 0.3)',
            borderColor: colorBlue,
            pointBorderColor: colorBlue,
            pointBackgroundColor: '#fff',
            pointHoverBackgroundColor: colorBlue,
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            data: liveData.map(datum => datum.archiveStreams),
          }),

        ],
      };

      let archSum = 0; let liveSum = 0; let
        live247Sum = 0;

      liveData.forEach((dataset) => {
        archSum += dataset.archiveStreams;
        liveSum += dataset.liveStreams;
        live247Sum += dataset.live247Streams;
      });

      const archMean = liveData.length / archSum;
      const liveMean = liveData.length / liveSum;
      const live247mean = liveData.length / live247Sum;

      const meanViewerCount = Math.ceil(2 / (archMean + liveMean + live247mean));
      const increment = (meanViewerCount < 50) ? 10
        : ((meanViewerCount < 1000) ? 50 : 100);

      // const hasNoData = (props.viewerCounts.length <= 0);


      return (
        (viewerHistory.length > 0) ? (
          <Panel
            lg={6}
            xl={9}
            md={12}
            title="Active Viewers"
            subhead="Real-time Viewer Statistics"
            rSubhead={`${liveTotal} total`}
          >
            <div>
              <ResponsiveContainer height={280} className="dashboard__active-users-chart">
                <Line
                  height={280}
                  data={liveChartData}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            stepSize: increment,
                            suggestedMin: totPointMin,
                            suggestedMax: totPointMax,
                            callback(value) {
                              return String(value)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            },
                          },
                          showLabelBackdrop: true,
                          type: 'linear',
                          display: true,
                          position: 'left',
                          id: 'y-axis-1',
                          labels: {
                            show: true,
                          },
                        },
                      ],
                    },
                  }
                      }
                />
              </ResponsiveContainer>
            </div>
          </Panel>
        ) : (
          <Panel
            lg={6}
            xl={9}
            md={12}
            title="Active Viewers"
            subhead="Real-time Viewer Statistics"
          ><div className="panel__refresh"><LoadingIcon /></div>
          </Panel>
        )

      );
    }
}


export default (RealtimeViewerChart);
