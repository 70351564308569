/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class SidebarCategory extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    // eslint-disable-next-line react/require-default-props
    custIcon: PropTypes.string,
    isNew: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    // eslint-disable-next-line react/require-default-props
    menuState: PropTypes.object,
  };

  static defaultProps = {
    icon: '',
    isNew: false,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const {
      title, icon, isNew, children, custIcon, menuState,
    } = this.props;
    const isMenuSmall = (menuState) ? menuState.collapse : false;
    const { collapse } = this.state;
    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': collapse,
    });

    return (
      <div className={categoryClass}>
        <button
          type="button"
          className="sidebar__link sidebar__category"
          onClick={this.toggle}
          onMouseEnter={(isMenuSmall) ? this.toggle : null}
        >
          {icon ? (
            <span className="sidebar__link-icon">
              <FontAwesomeIcon icon={icon} />
            </span>
          )
            : (custIcon) ? (
              <img src={custIcon} alt="menu item" />
            )
              : ''
          }
          <p className="sidebar__link-title">{title}
            {isNew && <span className="sidebar__category-new" />}
          </p>
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>
        <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
          <ul className="sidebar__submenu">
            <div>
              {children}
            </div>
          </ul>
        </Collapse>
      </div>
    );
  }
}
