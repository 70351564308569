/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  format, addDays,
} from 'date-fns';
import LoadingIcon from 'mdi-react/LoadingIcon';
import {
  Badge, Col, Container, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { PanelTitle } from '../../../../shared/components/LandingPanel';

const EventItem = ({ event, clientInfo, dispatch }) => {
  console.log('event is', event);
  const badgeStatus = (stat) => {
    switch (stat) {
      case 'live':
        return 'danger';
      case 'publishing':
      case 'published':
        return 'success';
      default:
        return 'info';
    }
  };
  const EventFrame = () => (
    <Col md={12} xl={3} lg={6} xs={12} />
  );
  return (
    (event.eventID !== null)
      ? (
        <Container key={event.eventID} className="rounded event-list-item">
          <Row className="heading">
            <Col>
              <h5><Link to={`/events/${event.eventID}`}> {event.title}</Link></h5>
            </Col>
            <Col className="text-right">
              <h5>{format(new Date(event.startDateTime), 'MMM do HH:mm a')}</h5>
            </Col>
            <Col md={1} className="text-center">
              <Badge color={badgeStatus(event.eventStatus)}>{event.eventStatus}</Badge>
            </Col>
          </Row>
          <Row>foo</Row>

        </Container>
      )
      : ''
  );
};

const mapStateToProps = state => ({
  // event: state.event,
  clientInfo: state.clientInfo,
});

EventItem.propTypes = {
  event: PropTypes.object,
  clientInfo: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(EventItem);
