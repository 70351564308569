import _objectSpread from"/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";var config={STATS:{URI:'https://playerstats-v2.invintus.com',auth:{authorization:process.env.REACT_APP_STATUAUTH,'wsc-api-key':process.env.REACT_APP_STATKEY},endpoints:{getViewerCounts:{get:'/stats/getViewerCounts'},getMostWatched:{get:'/stats/getMostWatched'},getBillingUsageMTD:{get:'/stats/getBillingUsageMTD'},getBillingUsageLM:{get:'/stats/getBillingUsageMTD'},getBillingUsageByDateRange:{get:'/stats/getBillingUsageByDateRange'}}},API:{URI:'https://api.v3.invintus.com/v2',auth:{authorization:'console','wsc-api-key':process.env.REACT_APP_WSC},endpoints:{clientInfo:{get:'/Console/getClientInfoByUser'},eventList:{get:'/Console/getEventsList'},event:{get:'/Console/getEvent'},sources:{get:'/Console/getEncoders'},categories:{get:'/Categories/getCategories'},preferences:{// NA
get:'/Embeded/getListingPreferenceByID'},search:{get:'/Search/general'}}},CRUD:{URI:'https://crud.v3.invintusmedia.com'// TODO: crud endpoints add here
}};/**
 * Takes in preferences/qs/etc. and truns them
 * into the correctly named properites + type values
 * the API requires
 *
 * @todo
 * lots of properties to handle, should this be in
 * different file?
 */var sanitizeQuery=function sanitizeQuery(body){var sanitized={};Object.keys(body).forEach(function(key){var value="".concat(body[key]);switch(key){case'id':sanitized.preferenceID=value;break;default:sanitized[key]=value;}});return sanitized;};/**
 *
 * @todo
 * - [ ] method? e.g. `config.endpoints[domain].<method>`
 *
 * @param {string} dest The URI service to call
 * @param {string} domain the content being fetched
 * @param {object} body the payload sent to the API
 */var getFetchRequest=function getFetchRequest(dest,domain,body){var url="".concat(config[dest].URI+config[dest].endpoints[domain].get);return{url:url,method:'POST',mode:'cors',headers:_objectSpread({'Content-Type':'application/json'},config[dest].auth),body:JSON.stringify(sanitizeQuery(body))};};// eslint-disable-next-line import/prefer-default-export
export{getFetchRequest};