/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */

/* eslint-disable no-console */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { Auth0Context } from '../../../Auth/react-auth0-wrapper';
import TopbarMenuLink from './TopbarMenuLink';


const mapStateToProps = state => ({ userInfo: state.userInfo });

class TopbarProfile extends PureComponent {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
  }

  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      userInfo: this.props.userInfo,
    };
    // console.log('header state', this.props);
  }


  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse, userInfo } = this.state;
    const { logout } = this.context;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={userInfo.data.picture} alt="avatar" />
          <p className="topbar__avatar-name">{userInfo.data.name}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="My Profile" icon="user" path="/account/profile" />
            {/* <TopbarMenuLink title="Calendar" icon="calendar-full" path="/default_pages/calendar" />
            <TopbarMenuLink title="Tasks" icon="list" path="/default_pages/calendar" />
            <TopbarMenuLink title="Inbox" icon="inbox" path="/mail" /> */}
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title="Account Settings" icon="cog" path="/account/settings" />
            {/* <TopbarMenuLink title="Lock Screen" icon="lock" path="/lock_screen" /> */}
            <a
              href="#"
              className="topbar__link"
              onClick={() => { logout({ returnTo: process.env.REACT_APP_LOGOUT_URI }); }}
            >
              <span className="topbar__link-icon lnr lnr-exit" />
              <p className="topbar__link-title">Log Out</p>
            </a>
          </div>
        </Collapse>
      </div>
    );
  }
}
export default connect(mapStateToProps)(TopbarProfile);
