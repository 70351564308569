import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Landing from '../../../Landing/index';
import Events from '../../../Events/index';
import Event from '../../../Events/Event/index';
import Create from '../../../Events/Create';
import DefaultPages from './DefaultPages';
import NotFound404 from '../../../DefaultPages/404/index';
import Account from './Account';
import Breadcrumbs from '../../../../shared/components/Breadcrumbs';

export default () => (
  <div>
    <Layout />

    <div className="container__wrap">
      <Breadcrumbs />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/account" component={Account} />
        <Route path="/events/create" component={Create} />
        <Route path="/events/:id" component={Event} />
        <Route path="/events" component={Events} />
        <Route path="/default_pages" component={DefaultPages} />
        <Route component={NotFound404} />
      </Switch>

    </div>
  </div>
);
