/* eslint-disable import/no-named-as-default */
/* eslint-disable no-console */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarMail from './TopbarMail';
import TopbarNotification from './TopbarNotification';
import TopbarClient from './TopbarClient';
// import Breadcrumbs from '../../../shared/components/Breadcrumbs';

// import TopbarSearch from './TopbarSearch';
// import TopbarLanguage from './TopbarLanguage';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;
    // console.log(this.props);
    return (
      <div>
        <div className="topbar">
          <div className="topbar__wrapper">
            <div className="topbar__left">
              <TopbarSidebarButton
                changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                changeSidebarVisibility={changeSidebarVisibility}
              />
              <Link className="topbar__logo" to="/" />

            </div>
            <div className="topbar__right">
              {/* <TopbarSearch /> */}
              <TopbarClient />
              <TopbarNotification new />
              <TopbarMail new />
              <TopbarProfile />
              {/* <TopbarLanguage /> */}
            </div>
          </div>

        </div>
        {/* <Breadcrumbs /> */}
      </div>

    );
  }
}

export default Topbar;
